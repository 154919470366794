import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUs.css";
import { motion, useInView } from "framer-motion";
import burger from "../../assets/aboutus/burger.jpg";
import falooda from "../../assets/aboutus/falooda.jpg";
import icecreams from "../../assets/aboutus/icecreams.jpg";
import mojito from "../../assets/aboutus/mojito.jpg";
import sandwich_group from "../../assets/aboutus/sandwich_group.jpg";
import club from "../../assets/aboutus/club_sandwich.jpg";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import patternLeftRight from "../../assets/patterns/patternleftright.png";
import BoottomMenu from "../home/BoottomMenu";
import VisionSection from "../vision/VisionSection";


function AboutUs() {
  const { t } = useTranslation();

  const [trans, setTrans] = useState(0);
  const aboutParaRef = useRef(null);
  const aboutHeadingRef = useRef(null);
  const aboutImageRef = useRef(null);
  const isaboutParaInView = useInView(aboutParaRef);
  const isaboutImageInView = useInView(aboutImageRef);
  const isaboutHeadingInView = useInView(aboutHeadingRef);

  const aboutParaAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
  };
  const aboutHeadingAnimation = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  const aboutImageAnimation = {
    hidden: { opacity: 0, transform: "translateX(50)" },
    visible: {
      opacity: 1,
      transform: "translateX(0)",
      transition: { duration: 2 },
    },
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setTrans(window.scrollY);
    });
  }, []);
  return (
    <>
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
      style={{ backgroundColor: "black" }}
    >
      <Container>
        <Row style={{ width: "100%" }}>
          <Col>
            <div className="oil-banner7">
              <div className="overlay7">
                <div className="oil-title7">
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <motion.div
              className="content-1-img-section"
              ref={aboutImageRef}
              variants={aboutImageAnimation}
              initial="hidden"
              animate={isaboutImageInView ? "visible" : "hidden"}
            >
              <Carousel pause={false}>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={burger}
                      alt="burger"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={falooda}
                      alt="falooda"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={icecreams}
                      alt="icecreams"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={mojito}
                      alt="mojito"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={club}
                      alt="sandwich sandwich_group"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="carousel-img-wrapper">
                    <img
                      src={sandwich_group}
                      alt="sandwich_group"
                      className="carousel-img"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </motion.div>
          </Col>

          <Col>
            <div className="content-1">
              <motion.div
                className="content-1-details"
                ref={aboutHeadingRef}
                variants={aboutHeadingAnimation}
                initial="hidden"
                animate={isaboutHeadingInView ? "visible" : "hidden"}
              >
                <h1 className="heading text-white">{t("home.about_the_company")}</h1>
              </motion.div>
              <motion.div
                className="content-1-details"
                ref={aboutParaRef}
                variants={aboutParaAnimation}
                initial="hidden"
                animate={isaboutParaInView ? "visible" : "hidden"}
              >
                <p className="paras">{t("about.company_description")}</p>
              </motion.div>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
    
    <VisionSection />

    <BoottomMenu />


    </>
  );
}

export default AboutUs;

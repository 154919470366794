import React, { useEffect, useState } from "react";
import contentLogo from "../../assets/content-logo/Frame 4 1 white fill.png";
import contentLogo2 from "../../assets/content-logo/Frame 5 1 white fill.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import patternLeftRight from "../../assets/patterns/patternleftright.png";
import "./VisionSection.css";

function VisionSection() {
  const [selectedContent, setSelectedContent] = useState("vision");
  const { t } = useTranslation();
  const [trans, setTrans] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setTrans(window.scrollY);
    });
  }, []);
  const handleClick = (content) => {
    setSelectedContent(content);
  };

  return (
    <div className="container1">
      <div className="section-heading">
        <div className="heading-text">{t("VisionSection.heading")}</div>
        <div className="heading-desc">{t("VisionSection.desc")}</div>
      </div>

      <div className="button-group">
        <button
          onClick={() => handleClick("vision")}
          className={`button ${selectedContent === "vision" ? "active" : ""}`}
        >
          {t("VisionSection.vision.heading")}
        </button>
        <button
          onClick={() => handleClick("mission")}
          className={`button ${selectedContent === "mission" ? "active" : ""}`}
        >
          {t("VisionSection.mission.heading")}
        </button>
      </div>
      <div className="section-content3">
        {selectedContent === "vision" && (
          <div className="content3">
            <motion.div animate={{ scale: [1, 1.2, 1] }} className="v-icon">
              <img src={contentLogo} alt="Vision Logo" />
            </motion.div>
            <div className="content3-heading text-white">
              {t("VisionSection.vision.heading")}
            </div>
            <div className="line"></div>
            <div className="content3-detail">
              {t("VisionSection.vision.detail")}
            </div>
          </div>
        )}
        {selectedContent === "mission" && (
          <div className="content3">
            <motion.div
              animate={{ rotate: [20, -30, 20] }}
              whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
              className="v-icon"
            >
              <img src={contentLogo2} alt="Mission Logo" />
            </motion.div>
            <div className="content3-heading">
              {t("VisionSection.mission.heading")}
            </div>
            <div className="line"></div>
            <div className="content3-detail">
              {t("VisionSection.mission.detail")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VisionSection;

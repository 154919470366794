import React from "react";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/logo/hft-logo1.png";
import logoHACCP from "../../assets/logo/HACCP.png";
import logoISO from "../../assets/logo/ISO.png";
import facebookicon from "../../assets/face.png";
import instagarmicon from "../../assets/instagram.png";
import "./Footer.css";
import { IoCallSharp } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import Patternright from '../../assets/patterns/patternright.png'


function Footer() {
  
  return (
    <div className="footer" style={{ backgroundColor: "black" }}>
      <div className="contactfooter">
        <div className="footerHeading">Phone</div>
        <div className="footerDetail">
          <IoCallSharp /> +974 70604928
        </div>
        <div className="footerDetail">
          <IoLogoWhatsapp />
          +974 70604928
        </div>
        <div className="footerHeading">Email</div>
        <div className="footerDetail-lower">
          <MdEmail />
          info@houseoftea.qa
        </div>
        <div className="social-media">
          <a
            href="https://www.facebook.com/houseofteaqatar"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            title="Facebook: https://www.facebook.com/houseofteaqatar"
          >
            <img src={facebookicon} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/houseofteaqatar/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            title="Instagram: https://www.instagram.com/houseofteaqatar/"
          >
            <img src={instagarmicon} alt="Instagram" />
          </a>
        </div>
      </div>
    
      <div className="logo-cerification">
        <img src={logoHACCP} alt="HACCP Certification" />
        <img src={logoISO} alt="ISO Certification" />
      </div>
     
       {/* <div className="logofooter">
        <img
          src={Patternright}
          alt="House Of Tea Logo"
          style={{ height: "170px", width: "170px" }}
        />
      </div> */}
    </div>
  );
}

export default Footer;

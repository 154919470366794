import React from "react";
import { useNavigate } from "react-router-dom";
import "./BottomMenu.css";

const BoottomMenu = () => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate("/menu");
    };
  
    return (
      <div className="menu-containers" onClick={handleClick}>
        <div className="coffee-icon">
          <div className="cup">
            <div className="handle"></div>
          </div>
          <div className="saucer"></div>
          <div className="steam steam-1"></div>
          <div className="steam steam-2"></div>
          <div className="steam steam-3"></div>
        </div>
        <span className="menu-text">Show Menu</span>
      </div>
    );
  };
  
  export default BoottomMenu;
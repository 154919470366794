import React from "react";
import { useTranslation } from "react-i18next";
import "./languageSwitcher.css";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <div className="language-switcher">
  <button className="language-button" onClick={() => changeLanguage("en")}>
    EN
  </button>
  <button className="language-button" onClick={() => changeLanguage("ar")}>
    AR
  </button>
</div>

  );
}

export default LanguageSwitcher;

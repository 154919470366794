import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../components/home/Home";
import IsoCertificates from "../components/certification/IsoCertificates";
import AboutUs from "../components/about-us/AboutUs";
import ContactUs from "../components/contact-us/ContactUs";
import { AnimatePresence } from "framer-motion";
import Menu from "./Menu/Menu";
import Cart from "./cart/Cart";
import StoreContact from "./storecontact/StoreContact";

function AnimatedRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route index path="/" element={<Home />} />
        <Route path="/iso-certifications" element={<IsoCertificates />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/store-contact" element={<StoreContact />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;

import React, { useContext, useState, useEffect } from "react";
import "./NavbarStyles.css";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import Dropdown from "./Dropdown";
import { FaAngleDown, FaShoppingCart } from "react-icons/fa";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import pagelogo from "../../assets/logo/page-logo.png";
import homeLogo from "../../assets/logo/hft-logo1.png";

function Navbar() {
  const { t } = useTranslation();
  const { getTotalItems } = useContext(CartContext);
  const [nav, setNav] = useState(false);
  const [bgnav, setBgnav] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [totalItems, setTotalItems] = useState(getTotalItems());

  useEffect(() => {
    setTotalItems(getTotalItems());
  }, [getTotalItems]);

  const handleNav = () => setNav(!nav);
  const location = useLocation().pathname;

  const logo = location === "/" ? homeLogo : pagelogo;

  const changeBackground = () => {
    setBgnav(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const navItems = [
    {
      id: 1,
      title: t("navbar.home"),
      path: "/",
      cName: "nav-item",
      icon: "",
    },
    {
      id: 2,
      title: t("navbar.about_us"),
      path: "/aboutus",
      cName: "nav-item",
      icon: "",
    },
    {
      id: 3,
      title: t("navbar.menu"),
      path: "/menu",
      cName: "nav-item",
      icon: "",
    },
    {
      id: 4,
      title: t("navbar.contact_us"),
      path: "/contactus",
      cName: "nav-item",
      icon: "",
    },
    {
      id: 5,  // Unique id
      title: t("navbar.store_contact"),
      path: "/store-contact",
      cName: "nav-item",
      icon: "",
    },
    {
      id: 6,  // Unique id
      title: `${t("navbar.cart")} (${totalItems})`,
      path: "/cart",
      cName: "nav-item",
      icon: <FaShoppingCart />,
    },
  ];

  return (
<div style={{ zIndex: '9999'}}>
<div className={bgnav ? "nav-header active" : "nav-header"} style={{borderBottom: '1px solid #1a1c1a',paddingBottom: '2rem'}}>
        <Link to={"/"}>
          <div className={bgnav ? "logo1" : "logo"}>
            <div className={bgnav ? "no-logo" : "yes-logo"}>
              <img src={logo} alt="House Of Tea" />
            </div>
            <div className={bgnav ? "yes-logo" : "no-logo"}>
              <img
                style={{ width: "150px", height: "150px" }}
                src={homeLogo}
                alt="House Of Tea"
              />
            </div>
          </div>
        </Link>
        <ul className={"nav-menu"}>
          {navItems.map((item) => {
            if (item.title === t("navbar.services")) {
              return (
                <li
                  key={item.id}
                  onClick={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link
                    className={
                      (location === "/" && !bgnav) || bgnav ? "white" : "black"
                    }
                    to={item.path}
                  >
                    {item.icon}
                    {item.title}
                    <FaAngleDown
                      color={
                        (location === "/" && !bgnav) || bgnav
                          ? "white"
                          : "black"
                      }
                      className="down-icon"
                    />
                  </Link>
                  {dropdown && <Dropdown />}
                </li>
              );
            }
            return (
              <li key={item.id} className={item.cName}>
                <Link
                  className={
                    (location === "/" && !bgnav) || bgnav ? "white" : "black"
                  }
                  to={item.path}
                >
                  {item.title && item.title} {""}
                  {item.icon} {""}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="hamburger" onClick={handleNav}>
          <HiOutlineMenuAlt4
            color={(location === "/" && !bgnav) || bgnav ? "white" : "black"}
            size={40}
          />
        </div>
        <div className={nav ? "mobile-menu active" : "mobile-menu"}>
          <ul className="mobile-nav">
            <Link className="mobile-link" to={"/"}>
              <li onClick={handleNav}>{t("navbar.home")}</li>
            </Link>
            <Link className="mobile-link" to={"/aboutus"}>
              <li onClick={handleNav}>{t("navbar.about_us")}</li>
            </Link>
            <Link className="mobile-link" to={"/menu"}>
              <li onClick={handleNav}>{t("navbar.menu")}</li>
            </Link>
            <Link className="mobile-link" to={"/contactus"}>
              <li onClick={handleNav}>{t("navbar.contact_us")}</li>
            </Link>
            <Link className="mobile-link" to={"/cart"}>
              <li onClick={handleNav}>
                {t("navbar.cart")} <FaShoppingCart /> ({totalItems})
              </li>
            </Link>
            <div
              style={{
                width: "100%",
                justifyContent: "space-around",
                display: "flex",
              }}
            >
              <IoMdClose size={40} onClick={handleNav} />
            </div>
          </ul>
        </div>
        <div>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React,{useEffect,useState} from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './FoodQuality.css'; 
import foodquality from "../../assets/foodandsafety.png";
import { useRef } from 'react';
import { motion } from 'framer-motion';

const FoodQuality = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  
  const [titleRef, titleInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [titleAnimationState, setTitleAnimationState] = useState("hidden");

  useEffect(() => {
    if (titleInView) {
      setTitleAnimationState("visible");
    }
  }, [titleInView]);

  const titleAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const imageAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'scale(1) rotate(0deg)' : 'scale(0.8) rotate(-10deg)',
    config: config.wobbly,
  });

  const descriptionAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0)' : 'translateX(50px)',
    config: config.gentle,
  });

  return (
    <div className="food-quality-container">
      <div className="food-quality-content">
        <motion.h1
          ref={titleRef}
          variants={titleAnimation}
          initial="hidden"
          animate={titleAnimationState}
          className="food-quality-title"
        >
          {t("home.food_and_safety")}
        </motion.h1>
        <div className="food-quality-details" ref={ref}>
          <animated.div style={imageAnimation} className="food-quality-image-container">
            <img src={foodquality} alt="Food Quality" className="food-quality-image" />
          </animated.div>
          <animated.div style={descriptionAnimation} className="food-quality-description-container">
            <p className="food-quality-description">
              {t("home.food_safety_description")}
            </p>
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default FoodQuality;
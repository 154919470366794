import React,{useState} from 'react';
import './WhyChooseUs.css';
import choose1 from "../../assets/why-choose/HOT-CHOCOLATE.jpg";
import choose2 from "../../assets/why-choose/gava.jpg";
import choose3 from "../../assets/why-choose/karak.jpg";
import choose4 from "../../assets/why-choose/malki.jpg";


import { GiCoffeeBeans } from "react-icons/gi";
import { SiCoffeescript } from "react-icons/si";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';


const WhyChooseUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [choose1, choose2, choose3, choose4];
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleclick = () => {
    navigate('/menu');
  };

  return (
    <div className="why-choose-us">
      <div className="container">
        <div className="content-wrapper">
          <motion.div 
            className="text-content"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3
            style={{zIndex:"99"}}
             className="small-title">{t("home.why_choose_us")}</h3>
            <h2
             style={{zIndex:"99", position:"sticky"}}
             className="big-title">{t("home.karaka_tea")}</h2>
            <p className="description">
              {t("home.why_choose_description")}
            </p>
            <div className="features">
              <motion.div 
                className="feature"
                whileHover={{ scale: 1.05, boxShadow: '0 10px 20px rgba(228, 70, 70, 0.2)' }}
              >
                <SiCoffeescript className="icon" style={{ fontSize: "80px", color: 'white' }} />
                <p>{t("home.why_choose_cup")}</p>
              </motion.div>
              <motion.div 
                className="feature"
                whileHover={{ scale: 1.05, boxShadow: '0 10px 20px rgba(228, 70, 70, 0.2)' }}
              >
                <GiCoffeeBeans className="icon" style={{ fontSize: "80px", color: 'white' }} />
                <p>{t("home.why_choose_coffeebean")}</p>
              </motion.div>
            </div>
            <motion.button onClick={handleclick}
              className="explore-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t("home.explore_button")}
            </motion.button>
          </motion.div>

          {/* Image grid for desktop */}
          <motion.div 
            className="image-content desktop-view"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="image-grid">
              {images.map((img, index) => (
                <motion.img 
                  key={index} 
                  src={img} 
                  alt={`Tea and Snacks ${index + 1}`} 
                  className={`img-${index + 1}`}
                  whileHover={{ scale: 1.1, rotate: index % 2 === 0 ? 5 : -5, zIndex: 2 }}
                  transition={{ type: "spring", stiffness: 300 }}
                />
              ))}
            </div>
          </motion.div>

          {/* Carousel for mobile view */}
          <motion.div 
            className="image-content mobile-view"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <motion.img 
              src={images[currentIndex]} 
              alt={`Carousel image ${currentIndex + 1}`}
              className="carousel-image"
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
            <div className="carousel-controls">
              <button onClick={handlePrev} className="prev-btn">‹</button>
              <button onClick={handleNext} className="next-btn">›</button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;

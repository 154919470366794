import React from "react";
import mapPlane from "../../assets/maps/plane.png";
import locationIcon from "../../assets/maps/location-icon.svg";
import "./Map.css";

function Map() {
  const stores = {
    muaither: {
      link: `https://maps.app.goo.gl/aYLLpURPxWdHCBFo7`,
    },
    thummam: {
      link: `https://maps.app.goo.gl/fY8mVQLQVvZSEQZd6`,
    },
    wakrah: {
      link: `https://maps.app.goo.gl/RWYTi1NL8YXpPCdR8`,
    },
    aziziya: {
      link: `https://maps.app.goo.gl/JrnJiooZyX7Ma97e6`,
    },
    markhiya: {
      link: `https://maps.app.goo.gl/CHWbbGptbX6LfeBX6`,
    },
    alkhor: {
      link: `https://maps.app.goo.gl/PN39LYAi6SFD2y2b8`,
    },
    lekhdaira: {
      link: `https://maps.app.goo.gl/v16cvoBmfgrPbG5U7`,
    },
    zubara: {
      link: `https://maps.app.goo.gl/oXvhCa1FVh9M5mGq7`,
    },
    citycentermall: {
      link: `https://maps.app.goo.gl/z9KXJoCg1p9UzhPX8`,
    },
    sealine: {
      link: `https://maps.app.goo.gl/j4b2FiNuxFYfoxKZA`,
    },
  };
  return (
    <svg
      width="100%"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <image href={mapPlane} x="0" y="0" width="100%" height="100%" />

      <image
        className="location"
        href={locationIcon}
        x="630"
        y="590"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.thummam.link, "_blank")}
      ></image>
      <text
        className="location"
        x="612"
        y="619"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.thummam.link, "_blank")}
      >
        Thumama
      </text>

      <image
        href={locationIcon}
        x="630"
        y="525"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.muaither.link, "_blank")}
      ></image>
      <text
        x="620"
        y="550"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.muaither.link, "_blank")}
      >
        Muaither
      </text>

      <image
        href={locationIcon}
        x="610"
        y="630"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.wakrah.link, "_blank")}
      ></image>
      <text
        x="586"
        y="658"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.wakrah.link, "_blank")}
      >
        Wakrah
      </text>

      <image
        href={locationIcon}
        x="620"
        y="556"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.aziziya.link, "_blank")}
      ></image>
      <text
        x="610"
        y="585"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.aziziya.link, "_blank")}
      >
        Aziziya
      </text>

      <image
        href={locationIcon}
        x="680"
        y="552"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.markhiya.link, "_blank")}
      ></image>
      <text
        x="663"
        y="580"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.markhiya.link, "_blank")}
      >
        Markhiya
      </text>

      <image
        href={locationIcon}
        x="810"
        y="495"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.alkhor.link, "_blank")}
      ></image>
      <text
        x="790"
        y="525"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.alkhor.link, "_blank")}
      >
        Al Khor
      </text>

      <image
        href={locationIcon}
        x="840"
        y="410"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.lekhdaira.link, "_blank")}
      ></image>
      <text
        x="828"
        y="441"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.lekhdaira.link, "_blank")}
      >
        Lekhdaira
      </text>

      <image
        href={locationIcon}
        x="790"
        y="410"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.zubara.link, "_blank")}
      ></image>
      <text
        x="765"
        y="438"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.zubara.link, "_blank")}
      >
        Zubara
      </text>

      <image
        href={locationIcon}
        x="655"
        y="580"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.citycentermall.link, "_blank")}
      ></image>
      <text
        x="665"
        y="610"
        fill="white"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.citycentermall.link, "_blank")}
      >
        City Center Mall
      </text>

      <image
        href={locationIcon}
        x="527"
        y="661"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.sealine.link, "_blank")}
      ></image>
      <text
        x="520"
        y="689"
        fill="black"
        font-size="12"
        font-weight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => window.open(stores.sealine.link, "_blank")}
      >
        Sealine
      </text>
    </svg>
  );
}
export default Map;

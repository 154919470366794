import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import burger from "../../assets/aboutus/burger.jpg";
import falooda from "../../assets/aboutus/falooda.jpg";
import icecreams from "../../assets/aboutus/icecreams.jpg";
import mojito from "../../assets/aboutus/mojito.jpg";
import sandwich_group from "../../assets/aboutus/sandwich_group.jpg";
import club from "../../assets/aboutus/club_sandwich.jpg";
import "slick-carousel/slick/slick-theme.css";
import { motion, useInView } from "framer-motion";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import patternLeftRight from "../../assets/patterns/patternright.png";
import Patternright from '../../assets/patterns/patternleft.png'
import Patternright2 from '../../assets/patterns/patternleftright.png'
import { Link } from "react-router-dom";
import Map from "../Map/Map";
import img1 from "../../assets/outletlogo/alfuttaim.png"
import img2 from "../../assets/outletlogo/qatarenergy.png";
import img3 from "../../assets/outletlogo/rail.png";
import img4 from "../../assets/outletlogo/shell.png";
import img5 from "../../assets/outletlogo/woqod.png";
import BoottomMenu from "./BoottomMenu";
import FoodQuality from "./FoodQuality";
import OurMenu from "./OurMenu";
import SpecializTea from "./SpecializeTea";
import WhyChooseUs from "./WhyChooseUs";


function Home() {
  const { t } = useTranslation();
  const [trans, setTrans] = useState(0);
  const aboutParaRef = useRef(null);
  const aboutHeadingRef = useRef(null);
  const aboutImageRef = useRef(null);
  const mapRef = useRef(null);
  const outletRef = useRef(null)
  const outletRef1 = useRef(null)
  const outletRef2 = useRef(null)
  const outletRef3 = useRef(null)
  const outletRef4 = useRef(null)
  const outletInview = useInView(outletRef,{ threshold: 0.1 })
  const outletInview1 = useInView(outletRef1,{ threshold: 0.1 })
  const outletInview2 = useInView(outletRef2,{ threshold: 0.1 })
  const outletInview3 = useInView(outletRef3,{ threshold: 0.1 })
  const outletInview4 = useInView(outletRef4, { threshold: 0.1 })
  const isaboutParaInView = useInView(aboutParaRef, { threshold: 0.1 });
  const isaboutImageInView = useInView(aboutImageRef, { threshold: 0.1 });
  const isaboutHeadingInView = useInView(aboutHeadingRef,{ threshold: 0.1 });
  const mapanimationInView = useInView(mapRef, { threshold: 0.1 });

  const aboutParaAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const outletAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const outletAnimation1 = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  }; const outletAnimation2 = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  }; const outletAnimation3 = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  }; const outletAnimation4 = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const MapAnimation = {
    hidden: { opacity: 0, x: 150 },
    visible: { opacity: 1, x: 0, transition: { duration: 2.5 } },
  };
  const aboutHeadingAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const aboutImageAnimation = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };


  const pageRef = useRef();
  useEffect(() => {
    pageRef.current.addEventListener("scroll", () => {
      console.log(pageRef.current.scrollY);
    });
    window.addEventListener("scroll", () => {
      setTrans(window.scrollY);
    });
  }, []);


  return (
    <>
      <motion.div
        ref={pageRef}
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth }}
      >
        <div className="home">
          <div className="hero vid-div">
            <video autoPlay loop muted playsInline id="video">
              <source src="https://res.cloudinary.com/dgkh0vnmz/video/upload/v1727597632/house-of-tea-bg-video_vtay6g.mp4" type="video/mp4" />
            </video>
            <div className="overlay10"></div>
            <div className="hero-slider-content"></div>
          </div>

          <Container>
            <motion.h1
              ref={aboutHeadingRef}
              variants={aboutHeadingAnimation}
              initial="hidden"
              animate={isaboutHeadingInView ? "visible" : "hidden"}
              style={{ color: "#ffff", textAlign: "center", marginBottom: "20px", fontFamily: "'Libre Baskerville', serif, sans-serif" }}>
              {t("home.about_the_company")}
            </motion.h1>

            <Row>
              <Col sm={12} md={6} style={{ zIndex: "1" }}>
                <motion.div
                  className="content-1-img-section"
                  ref={aboutImageRef}
                  variants={aboutImageAnimation}
                  initial="hidden"
                  animate={isaboutImageInView ? "visible" : "hidden"}
                >
                  <Carousel pause={false}>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={burger} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={falooda} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={icecreams} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={mojito} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={sandwich_group} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <div className="carousel-img-wrapper">
                        <img src={club} alt="oil pipe" className="carousel-img" />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </motion.div>
              </Col>

              {/* Right Column: Description and s */}
              <Col>
                <motion.div
                  className="content-1-details"
                  ref={aboutParaRef}
                  variants={aboutParaAnimation}
                  initial="hidden"
                  animate={isaboutParaInView ? "visible" : "hidden"}
                  style={{ zIndex: "9998", position: "relative", paddingTop: "5rem" }}
                >
                  <p className="para">{t("home.company_description")}</p>
                </motion.div>

                <div className="content-1-details">
                  <Link to={"aboutus"}>
                    <button className="content-btn">
                      <span>{t("home.more_info")}</span>
                      <svg
                        className="arrow-icon"
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="1.2em"
                        width="2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ margin: '4rem 0', zIndex: '9991', position: "relative" }}>
            <motion.h2
              ref={outletRef}
              variants={outletAnimation}
              initial="hidden"
              animate={outletInview ? "visible" : "hidden"}
              style={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: '1.5rem',
                color: '#ffff',
                zIndex: '9997',
                fontFamily: "'Libre Baskerville', serif, sans-serif"
              }}>
              {t("home.our_major_outlets")}
            </motion.h2>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '1rem',
              // zIndex: '999' 
            }}>
              <motion.div
                ref={outletRef}
                variants={outletAnimation}
                initial="hidden"
                animate={outletInview ? "visible" : "hidden"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '9991' }}>
                <img src={img1} alt="Al Futtaim" style={{ width: '13rem', height: '15rem', objectFit: 'contain' }} />
              </motion.div>
              <motion.div
                ref={outletRef1}
                variants={outletAnimation1}
                initial="hidden"
                animate={outletInview1 ? "visible" : "hidden"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '9991' }}>
                <img src={img2} alt="Qatar Energy" style={{ width: '13rem', height: '13rem', objectFit: 'contain' }} />
              </motion.div>
              <motion.div
                ref={outletRef2}
                variants={outletAnimation2}
                initial="hidden"
                animate={outletInview2 ? "visible" : "hidden"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '9991' }}>
                <img src={img3} alt="Rail" style={{ width: '14rem', height: '15rem', objectFit: 'contain' }} />
              </motion.div>
              <motion.div
                ref={outletRef3}
                variants={outletAnimation3}
                initial="hidden"
                animate={outletInview3 ? "visible" : "hidden"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '9991' }}>
                <img src={img4} alt="Shell" style={{ width: '13rem', height: '13rem', objectFit: 'contain' }} />
              </motion.div>
              <motion.div
                ref={outletRef4}
                variants={outletAnimation4}
                initial="hidden"
                animate={outletInview4 ? "visible" : "hidden"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '9991' }}>
                <img src={img5} alt="Woqod" style={{ width: '13rem', height: '13rem', objectFit: 'contain' }} />
              </motion.div>
            </div>
          </div>
          {/* Title Section */}
          <div
            className="pattern-left-home"
            style={{
              backgroundImage: `url("${Patternright}")`,
              transform: `translateY(-${trans}px)`,
              backgroundBlendMode: "multiply",
            }}
          ></div>
          <div
            className="pattern-about"
            style={{
              marginLeft: "auto",
              backgroundImage: `url("${patternLeftRight}")`,
              // transform: `translateY(-${trans}px)`,
            }}
          ></div>
          <div>
            <FoodQuality />
          </div>
          <div>
            <OurMenu />
          </div>
          {/* <div>
            <SpecializTea />
          </div> */}
          <div>
            <WhyChooseUs />
          </div>
          <div>
            <div className="home-section4">
              <div className="section-heading">
                <div className="heading-text">{t("home.Take_a_look_at_our_stores")}</div>
                <div className="heading-desc">{t("home.find_you_ideal_ambiance")}</div>
              </div>
              <motion.div
                ref={mapRef}
                variants={MapAnimation}
                initial="hidden"
                animate={mapanimationInView ? "visible" : "hidden"}
                className="map-section">
                <Map />
              </motion.div>
            </div>
          </div>
          {/* <Certification /> */}
        </div>
      </motion.div>
      <BoottomMenu />
        <div
        className="pattern-footer"
        style={{
          marginLeft: "auto",
          backgroundImage: `url("${Patternright2}")`,
          transform: `translateY(-${trans}px)`,
        }}
      ></div>
    </>
  );
}

export default Home;

export const navItems = [
  {
    id: 11,
    title: "HOME",
    path: "/",
    cName: "nav-item",
  },
  {
    id: 2,
    title: "ABOUT US",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 3,
    title: "MENU",
    path: "/menu",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "ORDER NOW",
    path: "/order-form",
    cName: "nav-item",
  },
  {
    id: 5,
    title: "CONTACT US",
    path: "/contactus",
    cName: "nav-item",
  },
  {
    id: 7,
    title: "FIND HOUSE OF TEA",
    path: "/store-contact",
    cName: "nav-item",
  },
  {
    id: 6,
    title: "CART",
    path: "/cart",
    cName: "nav-item",
  },
];

export const servicesDropdown = [
  {
    id: 12,
    title: "Oil & Gas",
    path: "/oilandgas",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Engineering",
    path: "/engineering",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "pre engineered building",
    path: "/pre-engineered",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Offshore/Onshore rental",
    path: "/off-shore-on-shore-rental",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "maintenance & refurbishment",
    path: "/maintanance-refurbishment",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "load testing and inspections",
    path: "/load-testing-and-inspection",
    cName: "submenu-item",
  },
];

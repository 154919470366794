import React, { useState, useEffect, useRef } from 'react';
import './OurMenu.css';
import menu1 from "../../assets/menu1.jpg";
import menu2 from "../../assets/menu2.jpg";
import menu3 from "../../assets/menu3.jpg";
import menu4 from "../../assets/menu4.jpg";
import menu5 from "../../assets/menu5.jpg";
import burger from "../../assets/home-menu/Burgers.jpg";
import club_sandwich from "../../assets/home-menu/Club-Sandwiches.jpg";
import icecream from "../../assets/home-menu/Ice-Cream-Dessert.jpg"
import pasta from "../../assets/home-menu/Italian-Pasta.jpg"
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';

const OurMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0);
  const menuParaRef = useRef(null);
  const menuTitleRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const ismenuParaInView = useInView(menuParaRef,{ threshold: 0.1 });
  const ismenuTitleInView = useInView(menuTitleRef,{ threshold: 0.1 });



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItems = [
    {
      title: t("home.burger"),
      image: burger,
    },
    {
      title: t("home.icecream"),
      image: icecream,
    },
    {
      title: t("home.pasta"),
      image: pasta,
    },
    {
      title: t("home.club_sandwich"),
      image: club_sandwich,
    },

  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      isMobile
        ? (prevIndex + 1) % menuItems.length
        : prevIndex >= menuItems.length - 3 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      isMobile
        ? (prevIndex - 1 + menuItems.length) % menuItems.length
        : prevIndex === 0 ? menuItems.length - 3 : prevIndex - 1
    );
  };

  const handleClick = () => {
    navigate('/menu');
  };

  const menuParaAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  };
  const menuTitleAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  return (
    <div className="our-menu-container">
      <div className="menu-sidebar">
        <motion.h2
          ref={menuTitleRef}
          variants={menuTitleAnimation}
          initial="hidden"
          style={{zIndex: '99'}}
          animate={ismenuTitleInView ? "visible" : "hidden"}
          className="menu-title1">{t("home.our_menu")}
        </motion.h2>
        <motion.p
          ref={menuParaRef}
          variants={menuParaAnimation}
          initial="hidden"
          style={{zIndex: '99'}}
          animate={ismenuParaInView ? "visible" : "hidden"}
          className="menu-description">
          {t("home.menu_description")}
        </motion.p>
      </div>
      <div className="menu-content">
        <button className="carousel-button prev" onClick={prevSlide}>
          <ChevronLeft />
        </button>
        <div className="menu-cards-container">
          <div
            className="menu-cards"
            style={{
              transform: `translateX(-${currentIndex * (isMobile ? 100 : 100 / 3)}%)`
            }}
          >
            {menuItems.map((item, index) => (
              <div className={`menu-card ${isMobile && index === currentIndex ? 'active' : ''}`} key={index}>
                <div className="card-image-container">
                  <img src={item.image} alt={item.title} className="card-image" />
                  <h3 className="card-title">{item.title.toUpperCase()}</h3>
                </div>
                <button className="get-more-button" onClick={handleClick}>{t("home.get_more")}</button>
              </div>
            ))}
          </div>
        </div>
        <button className="carousel-button next" onClick={nextSlide}>
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default OurMenu;
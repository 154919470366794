// import React, { useContext, useState, useCallback, useEffect } from "react";
// import "./Menu.css";
// import { CartContext } from "../../Context/CartContext";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useTranslation } from "react-i18next";
// import { baseUrl } from "../../constants";
// import crossIcon from "../../assets/icon-indicators/left-arrow.svg";

// const Menu = () => {
//   const { t, i18n } = useTranslation();
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [sidebarVisible, setSidebarVisible] = useState(true);
//   const [productData, setProductData] = useState({});

//   const [sortValue, setSort] = useState("default");
//   useEffect(() => {
//     if (!loading && sortValue === "price-low") {
//       productData[selectedCategory].sort((a, b) => {
//         if (!a.price) {
//           if (!b.price) {
//             console.log(
//               "min",
//               Math.min(...a.sizes.map((el) => el.price)),
//               Math.min(...b.sizes.map((el) => el.price))
//             );
//             return (
//               Math.min(...a.sizes.map((el) => el.price)) -
//               Math.min(...b.sizes.map((el) => el.price))
//             );
//           }
//           console.log(Math.min(...a.sizes.map((el) => el.price)) - b.price);
//           return Math.min(...a.sizes.map((el) => el.price)) - b.price;
//         }
//         console.log(a, b);
//         if (b.sizes) {
//           return a.price - Math.min(...b.sizes.map((el) => el.price));
//         }
//         return a.price - b.price;
//       });
//     } else if (!loading && sortValue === "price-high") {
//       productData[selectedCategory].sort((a, b) => {
//         if (!a.price) {
//           if (!b.price) {
//             return (
//               Math.max(...b.sizes.map((el) => el.price)) -
//               Math.max(...a.sizes.map((el) => el.price))
//             );
//           }
//           console.log(Math.max(...a.sizes.map((el) => el.price)) - b.price);
//           return b.price - Math.max(...a.sizes.map((el) => el.price));
//         }
//         console.log(a, b);
//         if (b.sizes) {
//           return Math.max(...b.sizes.map((el) => el.price)) - a.price;
//         }
//         return b.price - a.price;
//       });
//     } else if (!loading) {
//       productData[selectedCategory].sort((a, b) => {
//         if (a.name < b.name) {
//           return -1;
//         }
//         if (a.name > b.name) {
//           return 1;
//         }
//         return 0;
//       });
//     }
//     setProductData({ ...productData });
//   }, [sortValue]);

//   useEffect(() => {
//     setLoading(true);
//     let data = {};
//     fetch(baseUrl() + "/products/menu", {
//       headers: { "Accept-Language": i18n.language },
//     })
//       .then(function (a) {
//         return a.json();
//       })
//       .then((res) => {
//         //setTimeout(() => {
//         console.log(res.data);

//         res.data.map((e) => {
//           e.products.map((product, idx) => {
//             e.products[idx].image = baseUrl() + e.products[idx].image;
//           });

//           data[e.name] = [
//             ...e.products.sort((a, b) => {
//               if (a.name < b.name) {
//                 return -1;
//               }
//               if (a.name > b.name) {
//                 return 1;
//               }
//               return 0;
//             }),
//           ];
//         });
//         setSort("default");
//         setProductData({ ...data });
//         setSelectedCategory(Object.keys(data)[0]);
//         setLoading(false);

//         //}, 6000)
//       });
//   }, [i18n.language]);

//   const { addToCart } = useContext(CartContext);

//   const isMobileView = () => window.innerWidth <= 768;

//   const showToastMessage = () => {
//     toast.success(t("Menu.product_added"), {
//       position: "bottom-right",
//       autoClose: 2000,
//       closeOnClick: true,
//     });
//   };

//   const handleMenuClick = useCallback((category) => {
//     setSelectedCategory(category);
//     if (isMobileView()) {
//       setSidebarVisible(false);
//     }
//   }, []);

//   const toggleSidebar = useCallback(() => {
//     setSidebarVisible((prev) => !prev);
//   }, []);

//   const [selectedSize, setSelectedSize] = useState({});
//   useEffect(() => {
//     console.log(selectedSize);
//   }, [selectedSize]);
//   const handleSizeChange = (productId, size) => {
//     setSelectedSize((prev) => ({ ...prev, [productId]: size }));
//   };

//   const handleAddToCart = (product) => {
//     const selectedSizeForProduct = selectedSize[product.id];

//     if (product.sizes && product.sizes.length > 0) {
//       // Product has multiple sizes, check if size is selected
//       if (selectedSizeForProduct) {
//         const sizeDetails = product.sizes.find(
//           (s) => s.size === selectedSizeForProduct
//         );
//         if (sizeDetails) {
//           addToCart({
//             ...product,
//             selectedSize: selectedSizeForProduct,
//             selectedSizeAr: sizeDetails.size_ar,
//             price: sizeDetails.price,
//           });
//           setSelectedSize((prev) => ({ ...prev, [product.id]: "" }));
//           showToastMessage();
//         } else {
//           toast.error("Selected size is not available.", {
//             position: "bottom-right",
//             autoClose: 5000,
//           });
//         }
//       } else {
//         toast.error("Please select a size.", {
//           position: "bottom-right",
//           autoClose: 5000,
//         });
//       }
//     } else {
//       // Product does not have sizes, add directly
//       addToCart(product);
//       showToastMessage();
//     }
//   };

//   const currentProduct = productData[selectedCategory]?.find(
//     (p) => p.id === selectedProduct
//   );

//   const onSortChange = (e) => {
//     setSort(e.target.value);
//   };
//   return (
//     <div className="menu-container">
//       {/* <ToastContainer /> */}

//       <div className={`sidebar ${!sidebarVisible ? "hidden" : ""}`}>
//         <div className="menu-header">
//           <h2 className="menu-title">{t("Menu.menu")}</h2>
//           <button className="sidebar-close" onClick={toggleSidebar}>
//             <img src={crossIcon} height={30}></img>
//           </button>
//         </div>

//         {loading ? (
//           <p style={{ textAlign: "center", width: "100%", margin: "auto" }}>
//             loading...
//           </p>
//         ) : (
//           <div className="menu-list">
//             {Object.keys(productData).map((category) => (
//               <div
//                 key={category}
//                 onClick={() => handleMenuClick(category)}
//                 className={`menu-item ${
//                   selectedCategory === category ? "active" : ""
//                 }`}
//               >
//                 {category}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <div className={`content ${!sidebarVisible ? "expanded" : ""}`}>
//         <div className="content-header">
//           <button className="sidebar-toggle" onClick={toggleSidebar}>
//             Menu
//           </button>
//           <h2 className="category-title">
//             {loading && !sidebarVisible ? "loading.." : selectedCategory}
//           </h2>
//         </div>
//         <div className="sort-section">
//           {/* <p>Select Price Variant:</p> */}
//           <select
//             className="sort-selection select"
//             aria-placeholder="Sort Price"
//             name=""
//             id=""
//             onChange={onSortChange}
//             value={sortValue}
//           >
//             <option value="default">Sort Price</option>
//             <option value="price-low">Low Price</option>
//             <option value="price-high">High Price</option>
//           </select>
//         </div>
//         {loading ? (
//           <p style={{ textAlign: "center", width: "100%", margin: "auto" }}>
//             loading...
//           </p>
//         ) : (
//           <div className="product-grid">
//             {productData[selectedCategory]?.map((product) => (
//               <div
//                 key={product.id}
//                 className="product-card"
//                 onClick={() => setSelectedProduct(product.id)}
//               >
//                 <img
//                   src={product.image}
//                   alt={product.name}
//                   className="product-image"
//                   loading="lazy"
//                 />
//                 <h3 className="product-name" style={{ color: "black" }}>
//                   {t(`${product.name}`)}
//                 </h3>
//                 <p className="product-description" style={{ color: "black" }}>
//                   {t(`${product.description}`)}
//                 </p>

//                 {!product.price ? (
//                   <div className="size-options">
//                     {product.sizes.map((sizeOption) => (
//                       <button
//                         key={sizeOption.size}
//                         className={`size-option ${
//                           selectedSize[product.id] === sizeOption.size
//                             ? "selected"
//                             : ""
//                         }`}
//                         onClick={() =>
//                           handleSizeChange(product.id, sizeOption.size)
//                         }
//                       >
//                         {sizeOption.size} ({sizeOption.price.toFixed(2)} QR)
//                       </button>
//                     ))}
//                   </div>
//                 ) : (
//                   <p className="product-price">
//                     Price: {product.price.toFixed(2)} QR
//                   </p>
//                 )}
//                 <button
//                   className="add-to-cart-btn"
//                   onClick={() => handleAddToCart(product)}
//                 >
//                   {t("Menu.add_to_cart")}
//                 </button>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Menu;



import React, { useContext, useState, useCallback, useEffect } from "react";
import "./Menu.css";
import { CartContext } from "../../Context/CartContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { baseUrl } from "../../constants";
import crossIcon from "../../assets/icon-indicators/left-arrow.svg";
import { FaShoppingCart } from "react-icons/fa";
const Menu = () => {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const { getTotalItems } = useContext(CartContext);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [productData, setProductData] = useState({});
  const [totalItems, setTotalItems] = useState(getTotalItems());

  useEffect(() => {
    setTotalItems(getTotalItems());
  }, [getTotalItems]);


  const [sortValue, setSort] = useState("default");
  useEffect(() => {
    if (!loading && sortValue === "price-low") {
      productData[selectedCategory].sort((a, b) => {
        if (!a.price) {
          if (!b.price) {
            console.log(
              "min",
              Math.min(...a.sizes.map((el) => el.price)),
              Math.min(...b.sizes.map((el) => el.price))
            );
            return (
              Math.min(...a.sizes.map((el) => el.price)) -
              Math.min(...b.sizes.map((el) => el.price))
            );
          }
          console.log(Math.min(...a.sizes.map((el) => el.price)) - b.price);
          return Math.min(...a.sizes.map((el) => el.price)) - b.price;
        }
        console.log(a, b);
        if (b.sizes) {
          return a.price - Math.min(...b.sizes.map((el) => el.price));
        }
        return a.price - b.price;
      });
    } else if (!loading && sortValue === "price-high") {
      productData[selectedCategory].sort((a, b) => {
        if (!a.price) {
          if (!b.price) {
            return (
              Math.max(...b.sizes.map((el) => el.price)) -
              Math.max(...a.sizes.map((el) => el.price))
            );
          }
          console.log(Math.max(...a.sizes.map((el) => el.price)) - b.price);
          return b.price - Math.max(...a.sizes.map((el) => el.price));
        }
        console.log(a, b);
        if (b.sizes) {
          return Math.max(...b.sizes.map((el) => el.price)) - a.price;
        }
        return b.price - a.price;
      });
    } else if (!loading) {
      productData[selectedCategory].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    setProductData({ ...productData });
  }, [sortValue]);

  useEffect(() => {
    setLoading(true);
    let data = {};
    fetch(baseUrl() + "/products/menu", {
      headers: { "Accept-Language": i18n.language },
    })
      .then(function (a) {
        return a.json();
      })
      .then((res) => {
        //setTimeout(() => {
        console.log(res.data);

        res.data.map((e) => {
          e.products.map((product, idx) => {
            e.products[idx].image = baseUrl() + e.products[idx].image;
          });

          data[e.name] = [
            ...e.products.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }),
          ];
        });
        setSort("default");
        setProductData({ ...data });
        setSelectedCategory(Object.keys(data)[0]);
        setLoading(false);

        //}, 6000)
      });
  }, [i18n.language]);

  const { addToCart } = useContext(CartContext);

  const isMobileView = () => window.innerWidth <= 768;

  const showToastMessage = () => {
    toast.success(t("Menu.product_added"), {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const handleMenuClick = useCallback((category) => {
    setSelectedCategory(category);
    if (isMobileView()) {
      setSidebarVisible(false);
    }
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarVisible((prev) => !prev);
  }, []);

  const [selectedSize, setSelectedSize] = useState({});
  useEffect(() => {
    console.log(selectedSize);
  }, [selectedSize]);
  const handleSizeChange = (productId, size) => {
    setSelectedSize((prev) => ({ ...prev, [productId]: size }));
  };

  const handleAddToCart = (product) => {
    const selectedSizeForProduct = selectedSize[product.id];

    if (product.sizes && product.sizes.length > 0) {
      // Product has multiple sizes, check if size is selected
      if (selectedSizeForProduct) {
        const sizeDetails = product.sizes.find(
          (s) => s.size === selectedSizeForProduct
        );
        if (sizeDetails) {
          addToCart({
            ...product,
            selectedSize: selectedSizeForProduct,
            selectedSizeAr: sizeDetails.size_ar,
            price: sizeDetails.price,
          });
          setSelectedSize((prev) => ({ ...prev, [product.id]: "" }));
          showToastMessage();
        } else {
          toast.error("Selected size is not available.", {
            position: "bottom-right",
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Please select a size.", {
          position: "bottom-right",
          autoClose: 5000,
        });
      }
    } else {
      // Product does not have sizes, add directly
      addToCart(product);
      showToastMessage();
    }
  };

  const currentProduct = productData[selectedCategory]?.find(
    (p) => p.id === selectedProduct
  );

  const onSortChange = (e) => {
    setSort(e.target.value);
  };
  return (
    <div className="menu-container">
      {/* <ToastContainer /> */}

      <div className={`sidebar ${!sidebarVisible ? "hidden" : ""}`}>
        <div className="menu-header">
          <h2 className="menu-title">{t("Menu.menu")}</h2>
          <button className="sidebar-close" onClick={toggleSidebar}>
            <img src={crossIcon} height={30}></img>
          </button>
        </div>

        {loading ? (
          <p style={{ textAlign: "center", width: "100%", margin: "auto" }}>
            loading...
          </p>
        ) : (
          <div className="menu-list">
            {Object.keys(productData).map((category) => (
              <div
                key={category}
                onClick={() => handleMenuClick(category)}
                className={`menu-item ${selectedCategory === category ? "active" : ""
                  }`}
              >
                {category}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={`content ${!sidebarVisible ? "expanded" : ""}`}>
        <div className="content-header">
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            Menu
          </button>
          <h2 className="category-title">
            {loading && !sidebarVisible ? "loading.." : selectedCategory}
          </h2>
          <div className="mobile-cart-btn bg-white text-black flex items-center p-3 rounded">
            <Link to="/cart">
            <FaShoppingCart className="text-black" />
            </Link>
            <span className="ml-1">({totalItems})</span>
          </div>

        </div>
        <div className="sort-section">
          {/* <p>Select Price Variant:</p> */}
          <select
            className="sort-selection select"
            aria-placeholder="Sort Price"
            name=""
            id=""
            onChange={onSortChange}
            value={sortValue}
          >
            <option value="default">Sort Price</option>
            <option value="price-low">Low Price</option>
            <option value="price-high">High Price</option>
          </select>
        </div>
        {loading ? (
          <p style={{ textAlign: "center", width: "100%", margin: "auto" }}>
            loading...
          </p>
        ) : (
          <div className="product-grid">
            {productData[selectedCategory]?.map((product) => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => setSelectedProduct(product.id)}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                  loading="lazy"
                />
                <h3 className="product-name" style={{ color: "black" }}>
                  {t(`${product.name}`)}
                </h3>
                <p className="product-description" style={{ color: "black" }}>
                  {t(`${product.description}`)}
                </p>

                {!product.price ? (
                  <div className="size-options">
                    {product.sizes.map((sizeOption) => (
                      <button
                        key={sizeOption.size}
                        className={`size-option ${selectedSize[product.id] === sizeOption.size
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          handleSizeChange(product.id, sizeOption.size)
                        }
                      >
                        {sizeOption.size} ({sizeOption.price.toFixed(2)} QR)
                      </button>
                    ))}
                  </div>
                ) : (
                  <p className="product-price">
                    Price: {product.price.toFixed(2)} QR
                  </p>
                )}
                <button
                  className="add-to-cart-btn"
                  onClick={() => handleAddToCart(product)}
                >
                  {t("Menu.add_to_cart")}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;

import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../Context/CartContext";
import "./Cart.css";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { FaLocationArrow } from "react-icons/fa";

const Cart = () => {
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const { t, i18n } = useTranslation();
  const {
    getCartItems,
    removeFromCart,
    updateQuantity,
    clearCart,
    getSelectedStore,
    selectStore,
  } = useContext(CartContext);
  const [cartItems, setCartItems] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  useEffect(() => {
    setCartItems(getCartItems());
    setSelectedStore(getSelectedStore());
  }, [getCartItems]);

  // const findNearestStore = async () => {
  //   try {
  //     const location = await getUserLocation();

  //     const nearestStore = storeLocations.reduce(
  //       (nearest, store) => {
  //         const distance = calculateDistance(
  //           location.latitude,
  //           location.longitude,
  //           store.latitude,
  //           store.longitude
  //         );
  //         return distance < nearest.distance ? { ...store, distance } : nearest;
  //       },
  //       { distance: Infinity }
  //     );

  //     setSelectedStore(nearestStore);
  //     selectStore(nearestStore);
  //     setDistance(nearestStore.distance);

  //     toast.success(`Nearest store found: ${nearestStore.name}`, {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //   } catch (error) {
  //     if (error.code === 1) {
  //       toast.error(
  //         "Location access denied. Please enable location services to find the nearest store.",
  //         {
  //           position: "bottom-right",
  //           autoClose: 5000,
  //         }
  //       );
  //     } else {
  //       toast.error("Unable to get your location. Please try again.", {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //       });
  //     }
  //   }
  // };
  const findNearestStore = async () => {
    try {
      // Get the user's location using a helper function like getUserLocation()
      const location = await getUserLocation();

      // Ensure you have a list of store locations to compare against
      if (!storeLocations || storeLocations.length === 0) {
        throw new Error("No store locations available.");
      }

      // Find the nearest store by reducing over the storeLocations array
      const nearestStore = storeLocations.reduce(
        (nearest, store) => {
          // Calculate the distance between the user's location and each store
          const distance = calculateDistance(
            location.latitude,
            location.longitude,
            store.latitude,
            store.longitude
          );

          // If this store is closer than the current nearest, update the nearest store
          return distance < nearest.distance ? { ...store, distance } : nearest;
        },
        { distance: Infinity } // Start with an infinitely large distance
      );

      // Set the selected store and distance
      setSelectedStore(nearestStore);
      selectStore(nearestStore);
      setDistance(nearestStore.distance);

      // Notify the user about the nearest store
      toast.success(`Nearest store found: ${nearestStore.name}`, {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      // Handle location access denied or other errors
      if (error.code === 1) {
        toast.error(
          "Location access denied. Please enable location services to find the nearest store.",
          {
            position: "bottom-right",
            autoClose: 5000,
          }
        );
      } else {
        toast.error("Unable to get your location. Please try again.", {
          position: "bottom-right",
          autoClose: 5000,
        });
      }
    }
  };

  const [address, setAddress] = useState({
    zone: "",
    street: "",
    building: "",
    details: "",
  });
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const deliveryCharge = 10;

  const storeLocations = [
    {
      name: "Thumama ",
      latitude: 25.222438996740422,
      longitude: 51.550536562514615,
      whatsappNumber: "+97431235757",
      excatlocation: "https://maps.app.goo.gl/fY8mVQLQVvZSEQZd6",
    },
    {
      name: "Muaithar ",
      latitude: 25.29572332548531,
      longitude: 51.407174985912846,
      whatsappNumber: "+97431462211",
      excatlocation: "https://maps.app.goo.gl/aYLLpURPxWdHCBFo7",
    },
    {
      name: "Wakrah ",
      latitude: 25.200882610390817,
      longitude: 51.588306019650865,
      whatsappNumber: "+97431366767",
      excatlocation: "https://maps.app.goo.gl/RWYTi1NL8YXpPCdR8",
    },
    {
      name: " Aziziya",
      latitude: 25.245825444089515,
      longitude: 51.446762153624036,
      whatsappNumber: "+97474410010",
      excatlocation: "https://maps.app.goo.gl/JrnJiooZyX7Ma97e6",
    },
    {
      name: "Markhiya ",
      latitude: 25.338296672621958,
      longitude: 51.491324118099705,
      whatsappNumber: "+97477298787",
      excatlocation: "https://maps.app.goo.gl/JrnJiooZyX7Ma97e6",
    },
    {
      name: "Al Khor ",
      latitude: 25.67683018797721,
      longitude: 51.50235561004744,
      whatsappNumber: "+97470377366",
      excatlocation: "https://maps.app.goo.gl/PN39LYAi6SFD2y2b8",
    },
    {
      name: "Lekhdaira ",
      latitude: 25.84792563866012,
      longitude: 51.350088871164644,
      whatsappNumber: "+97477289797",
      excatlocation: "https://maps.app.goo.gl/v16cvoBmfgrPbG5U7",
    },
    {
      name: "Zubara ",
      latitude: 25.825738803487717,
      longitude: 51.34830822276559,
      whatsappNumber: "+97470602132",
      excatlocation: "https://maps.app.goo.gl/oXvhCa1FVh9M5mGq7",
    },
  ];

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setLocation({ latitude, longitude });
          resolve({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
          reject(error);
        },
        { enableHighAccuracy: true }
      );
    });
  };

  const handleStoreChange = (e) => {
    const store = storeLocations.find((store) => store.name === e.target.value);
    setSelectedStore(store);
    selectStore(store);
    setDistance(null);
  };

  const removeFromCartMessage = () => {
    toast.success("Product Successfully Removed From Cart!", {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const removeQtyCartMessage = () => {
    toast.success("Removed Product Quantity Successfully!", {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const addQtyCartMessage = () => {
    toast.success("Added Product Quantity Successfully!", {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const getTotalQuantity = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleQuantityChange = (index, change) => {
    const currentQuantity = cartItems[index].quantity;
    const newQuantity = currentQuantity + change;

    if (newQuantity <= 0) {
      handleRemoveItem(index);
    } else {
      updateQuantity(index, newQuantity);
      change > 0 ? addQtyCartMessage() : removeQtyCartMessage();
    }
  };
  const handleRemoveItem = (index) => {
    removeFromCart(index);
    removeFromCartMessage();
  };
  const handleOrderNow = () => {
    if (!selectedStore) {
      toast.error("Please select a store before proceeding with the order.", {
        position: "bottom-right",
        autoClose: 5000,
      });
      return;
    }
    if (!address.zone || !address.street || !address.building) {
      toast.error(
        "Please enter your address before proceeding with the order.",
        {
          position: "bottom-right",
          autoClose: 5000,
        }
      );
      return;
    }
    const totalPrice = getTotalPrice();
    const totalQuantity = getTotalQuantity();
    const deliveryFee = distance > 10 ? deliveryCharge : 0;
    const grandTotal = totalPrice + deliveryFee;

    const storeLocationInfo = selectedStore
      ? `Store Location: ${selectedStore.name}\n\nLocation Link: ${selectedStore.excatlocation}`
      : "";

    const whatsappNumber = selectedStore.whatsappNumber;
    const baseUrl = "https://wa.me/";
    const encodedMessage = encodeURIComponent(
      `Order Details:\n\n${cartItems
        .map(
          (item, index) =>
            `${index + 1}. Product: ${item.name}\n Size: ${
              item.selectedSize
            }\nQuantity: ${item.quantity}\nPrice: ${item.price} QAR\n\n`
        )
        .join(
          "\n\n"
        )}\n\nTotal Quantity: ${totalQuantity}\nTotal Price: ${totalPrice.toFixed(
        2
      )} QAR\nGrand Total: ${grandTotal.toFixed(2)} QAR\n\nAddress: \n\nZone: ${
        address.zone
      }, \n\nStreet: ${address.street}, \n\nBuilding NO: ${
        address.building
      },\n\nLandMark: ${address.details}\n\n\n`
    );

    const locationMessage = location
      ? `\n\n\n Customer Location: https://maps.google.com/?q=${location.latitude},${location.longitude}`
      : "";

    const sendMessage = (locationMessage) => {
      const whatsappUrl = `${baseUrl}${whatsappNumber}?text=${encodedMessage}${storeLocationInfo}${locationMessage}`;
      window.open(whatsappUrl, "_blank");
    };

    clearCart();

    if (location) {
      sendMessage(locationMessage);
    }
    // else {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       setLocation({ latitude, longitude });
    //       if (selectedStore) {
    //         const calculatedDistance = calculateDistance(
    //           selectedStore.latitude,
    //           selectedStore.longitude,
    //           latitude,
    //           longitude
    //         );
    //         setDistance(calculatedDistance);
    //       }
    //       const locationMessage = `\n\n\n Customer Location: https://maps.google.com/?q=${latitude},${longitude}`;
    //       sendMessage(locationMessage);
    //     },
    //     (error) => {
    //       console.error("Error getting location:", error);
    //       sendMessage("");
    //     },
    //     { enableHighAccuracy: true }

    //   );
    // }
    else {
      // Check if geolocation is supported
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Extract latitude and longitude from the position object
            const { latitude, longitude } = position.coords;

            // Set the user's location
            setLocation({ latitude, longitude });

            // If a store is selected, calculate the distance between the store and the user's location
            if (selectedStore) {
              const calculatedDistance = calculateDistance(
                selectedStore.latitude,
                selectedStore.longitude,
                latitude,
                longitude
              );

              // Set the calculated distance
              setDistance(calculatedDistance);
            }

            // Create a Google Maps link with the user's current location
            const locationMessage = `\n\n\nCustomer Location: https://maps.google.com/?q=${latitude},${longitude}`;

            // Send the location message
            sendMessage(locationMessage);
          },
          (error) => {
            // Handle location retrieval errors
            console.error("Error getting location:", error);

            // Optionally, send an empty message or error message to the user
            sendMessage("Unable to retrieve your location.");
          },
          {
            enableHighAccuracy: true, // High-accuracy mode for precise location
            timeout: 10000, // Wait 10 seconds before giving up
            maximumAge: 0, // No cached location; get fresh data
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        sendMessage("Geolocation is not supported by your device.");
      }
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  useEffect(() => {
    if (selectedStore && location) {
      const calculatedDistance = calculateDistance(
        selectedStore.latitude,
        selectedStore.longitude,
        location.latitude,
        location.longitude
      );
      setDistance(calculatedDistance);
    }
  }, [selectedStore, location]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const saveAddress = () => {
    setShowAddressForm(false);
    setIsEditingAddress(true); // Update the button text to "Edit Address"
  };

  return (
    <div className="cart-container">
      {cartItems.length === 0 ? (
        <div className="empty-cart-text">
          <h4>{t("cart.empty_cart")}</h4>
          <Link
            to="/menu"
            style={{ color: "white", textDecoration: "underline" }}
          >
            {t("cart.Continue_Shopping")}
          </Link>
        </div>
      ) : (
        <>
          <h2 className="cart-title" style={{ fontFamily: "Baskerville" }}>
            {t("cart.Your_Cart")}
          </h2>
          <div className="cart-items">
            {cartItems.map((item, index) => (
              <div key={index} className="cart-item">
                <img
                  src={item.image}
                  alt={item.name}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  <h3>
                    {i18n.language === "ar" && item.name_ar
                      ? item.name_ar
                      : item.name}
                  </h3>
                  {item.selectedSize && (
                    <p className="cart-item-size">
                      {t("cart.Size")} : {""}
                      {i18n.language === "ar" && item.selectedSizeAr
                        ? item.selectedSizeAr
                        : item.selectedSize}
                    </p>
                  )}
                  <div className="quantity-control">
                    <button
                      className="quantity-button"
                      onClick={() => handleQuantityChange(index, -1)}
                    >
                      -
                    </button>
                    <span className="quantity">{item.quantity}</span>
                    <button
                      className="quantity-button"
                      onClick={() => handleQuantityChange(index, 1)}
                    >
                      +
                    </button>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveItem(index)}
                      style={{ marginLeft: "auto" }} // Ensure trash icon is at the end
                    >
                      <FaTrash />
                    </button>
                  </div>
                  {item.price && (
                    <div className="item-price">
                      {t("Menu.price", { price: item.price?.toFixed(2) })}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="cart-summary">
            <h3>
              {t("cart.total_quantity")}
              <span className="summary-value">{getTotalQuantity()} Qty</span>
            </h3>
            <h3>
              {t("cart.total_price")}
              <span className="summary-value">
                {getTotalPrice().toFixed(2)} QAR
              </span>
            </h3>
            {/* {distance > 10 && (
              <h3>
                {t("cart.delivery_charge")}
                <span className="summary-value">{deliveryCharge} QAR</span>
              </h3>
            )} */}

            <div className="store-selection">
              <div style={{ paddingTop: "10px" }}>
                <button
                  onClick={findNearestStore}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#16a085",
                    color: "white",
                    border: "none",
                    padding: "10px 15px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  <FaLocationArrow style={{ marginRight: "5px" }} />
                  {/* {t("cart.find_current_location")} */}
                  {t("cart.find_current_location")}
                </button>
              </div>
              <h3>{t("cart.select_store")}</h3>
              <select
                value={selectedStore ? selectedStore.name : ""}
                onChange={handleStoreChange}
                style={{ padding: "8px", fontSize: "16px" }}
              >
                <option value="" disabled>
                  {t("cart.select_store")}
                </option>
                {storeLocations.map((store, index) => (
                  <option key={index} value={store.name}>
                    {store.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="address-section">
              <button
                className="add-address-button"
                onClick={() => setShowAddressForm(!showAddressForm)}
              >
                {isEditingAddress
                  ? t("cart.edit_address")
                  : t("cart.add_address")}
              </button>
              {showAddressForm && (
                <div className="address-form">
                  <h3>{t("cart.enter_address")}</h3>
                  <form>
                    <input
                      type="text"
                      name="zone"
                      placeholder={t("Zone")}
                      value={address.zone}
                      onChange={handleAddressChange}
                    />
                    <input
                      type="text"
                      name="street"
                      placeholder={t("Street")}
                      value={address.street}
                      onChange={handleAddressChange}
                    />
                    <input
                      type="text"
                      name="building"
                      placeholder={t("Building")}
                      value={address.building}
                      onChange={handleAddressChange}
                    />
                    <textarea
                      name="details"
                      placeholder={t("Landmark")}
                      value={address.details}
                      onChange={handleAddressChange}
                    />
                    <button
                      type="button"
                      onClick={saveAddress}
                      className="save-address-button"
                    >
                      {t("Save Address")}
                    </button>
                  </form>
                </div>
              )}
              {!showAddressForm && address.zone && (
                <div className="address-card mt-3">
                  {/* <h3>{t("cart.address_details")}</h3> */}
                  <h3>Address</h3>

                  <p>{address.zone}</p>
                  <p>{address.street}</p>
                  <p>{address.building}</p>
                  <p>{address.details}</p>
                </div>
              )}
            </div>
            <div className="order-now">
              <button className="order-now-button" onClick={handleOrderNow}>
                {t("cart.order_now")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
